import {Injectable} from "@angular/core";
import {
    readContract,
} from "@wagmi/core";
import {config} from "../utils/web3modal";
import {environment} from "../../../environments/environment";
import {ConnectorService} from "./connector.service";
import {ShopProductResponse} from "../models/models";
import {ToastService, ToastType} from "./toast.service";


@Injectable({providedIn: 'root'})
export class ClickerRewardsService {

    get contractAddress(): string {
        return environment.clickerRewardsContract as string;
    }

    get abi(): any {
        return environment.clickerRewardsAbi;
    }

    constructor(
        private _connectorService: ConnectorService,
        private _toastService: ToastService,
    ) {
    }

    public async getProductsRaw(): Promise<ShopProductResponse[]> {
        return await this.readContractArray<any>(config, {
            address: this.contractAddress as any,
            abi: this.abi,
            functionName: 'getProducts',
            args: [0, Number.MAX_SAFE_INTEGER]
        });
    }

    private async readContractArray<T>(config: any, parameters: any): Promise<T[]> {
        try {
            return await readContract(config, parameters) as any;
        } catch (e: any) {
            this._toastService.open(e?.shortMessage ?? e?.message, ToastType.ERROR);
            return [];
        }
    }

    public async claimProduct(address: string, productId: number, callbackSigned: () => void = () => {}): Promise<{ success: boolean }> {
        try {
            const obj = {
                abi: this.abi,
                address: this.contractAddress,
                functionName: 'claimProduct',
                args: [productId],
                confirmations: 1,
            };
            const result = await this._connectorService.signTransaction([obj], callbackSigned);
            if (result?.success) {
                return {success: true};
            }
        } catch (e: any) {

        }
        return {success: false};
    }

}
