<div class="container-modal" id="modal-tutorial" (click)="$event.stopImmediatePropagation(); closeModal()">
    <div class="wrap-container-frame" (click)="$event.stopImmediatePropagation()">
        <img src="/assets/images/ic-close.png" height="58"  class="close-button" (click)="closeModal()">
        <div class="header flex flex-row flex-wrap items-center" style="box-sizing: border-box; gap: 6px">
            <span class="grow">Claim prizes</span>
            <div class="flex flex-row items-center" style="gap: 10px; width: 100%; max-width: 300px">
                <img src="/assets/images/ic-gem-small.png" width="32" height="32" />
                <div class="container-progress relative grow">
                    <div class="progress" *ngIf="progress" [ngStyle]="{'width' : progress.progress + '%'}"></div>
                    <div class="stroke"></div>
                    <span class="text-value flex items-center justify-center centerText" *ngIf="progress">{{points}}/{{progress.next_value}}</span>
                </div>
                <div class="level flex flex-col items-center justify-center">
                    <span class="centerText">{{3}}</span>
                </div>
            </div>
        </div>
        <div class="full-size flex flex-row items-center justify-center" *ngIf="isLoading || isTransacting; else viewList">
            <app-loader-brigade></app-loader-brigade>
            <span class="check-your-wallet centerText full-width" style="margin-top: 140px" *ngIf="isTransacting">{{!isVerifying ? 'Check your wallet...' : 'Verifying transaction...'}}</span>
        </div>
        <ng-template #viewList>
            <div class="container-scroll full-width flex flex-row flex-wrap justify-center no-scrollbar" style="gap: 12px;">
                <div *ngFor="let item of list" class="item flex flex-col items-center" style="gap: 8px">
                    <div class="wrap-image relative">
                        <img class="full-width object-contain" [src]="item.img" />
                        <span class="name-text absolute centerText ellipsis-nowrap">{{item.name}}</span>
                        <div class="info-button">
                            <img class="full-size" src="/assets/images/ic-info-18.png" (mouseenter)="enterTooltip($event, item)" (mouseleave)="leaveTooltip($event)" />
                            <div class="tooltip-card" *ngIf="tooltip === item">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                        <div class="absolute flex flex-row container-value items-center">
                            <img class="image" src="/assets/images/ic-gem-small-2.png" width="12" height="12"  />
                            <span>{{item.value}}</span>
                        </div>
                    </div>
                    <div class="item-button" [ngClass]="{'locked': !item.claimable, 'disabled': item.isDisabled}" (click)="claimReward(item)">
                        {{item.isDisabled ? 'AVAILABLE SOON' : (item.claimable ? 'CLAIM' : 'LOCKED')}}
                    </div>
                    <div class="level flex flex-col items-center justify-center" [ngClass]="{'disabled': item.isDisabled}">
                        <span class="centerText">{{item.pos}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
