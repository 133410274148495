import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {ClickerRewardsService} from "../../core/services/clicker-rewards.service";
import {ToastService, ToastType} from "../../core/services/toast.service";
import {PriceItem} from "../../core/models/models";
import {SessionQuery} from "../../core/stores/session/session.query";
import {ClickerService} from "../../core/services/clicker.service";
import {LoaderBrigadeComponent} from "../loader-brigade/loader-brigade.component";

@Component({
  selector: 'app-prices-modal',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    NgStyle,
    NgClass,
    LoaderBrigadeComponent
  ],
  templateUrl: './prices-modal.component.html',
  styleUrl: './prices-modal.component.css'
})
export class PricesModalComponent implements OnInit{

  private _clickerRewardsService = inject(ClickerRewardsService);
  private clickerService = inject(ClickerService);
  private _toastService = inject(ToastService);
  private _session = inject(SessionQuery);

  @Output() onClose = new EventEmitter<boolean>();

  isLoading = false;
  isTransacting = false;
  isVerifying = false;

  list: PriceItem[] = [];
  points: number = 0;

  progress: {claimed: number, next_value: number, progress: number} | undefined;

  private defaultList: any[] = [
    {points: 350, name: 'Starter Seeds Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 650, name: 'Character Skin Crate, Currency Bag Small', img: '/assets/images/chest-placeholder.png'},
    {points: 1000, name: 'Decor Item Crate, Seeds Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 1500, name: 'Currency Bag Medium, Genes Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 2000, name: 'Premium Character Skin Crate, Seeds Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 2500, name: 'Decor Item Crate, Seeds Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 3000, name: 'Tools Crate, Genes Crate, Crop Crate', img: '/assets/images/chest-placeholder.png'},
    {points: 3500, name: 'Premium Decor Item, Premium Character Skin, Currency Bag Large', img: '/assets/images/chest-placeholder.png'},
  ];

  ngOnInit() {
    this.refresh();
  }

  private async refresh() {
    this.isLoading = true;
    this.points = await this.clickerService.readPoints(this._session.address!!);
    this._clickerRewardsService.getProductsRaw().then((res) => {
      res.forEach((item: any) => {
        item.id = Number(item.id)
        item.value = Number(item.value)
        delete item.assets;
      });
      const list: any[] = [];
      res.sort((a: any, b: any) => a.value - b.value).filter((item: any) => item.isActive).forEach((item: any, index: number) => {
        list.push({
          id: item.id,
          name: item.name,
          value: item.value,
          img: 'https://assets.tacostudios.io/brigade/assets/brigade-rewards/' + item.image,
          pos: index + 1,
          claimable: item.value <= this.points,
          isDisabled: false,
        });
      });
      this.defaultList.forEach((item: any) => {
        if (!list.find((i) => i.value === item.points)) {
          list.push({
            id: -1,
            name: item.name,
            value: item.points,
            img: item.img,
            pos: list.length + 1,
            isDisabled: true,
          });
        }
      });
      this.list = list;
      this.progress = this.calculateProgress(this.list, this.points);
    }).catch(() => {
      this._toastService.open('Error loading rewards', ToastType.ERROR);
    }).finally(() => {
        this.isLoading = false;
    });
  }

  private calculateProgress(list: PriceItem[], points: number): any {
    let next_value = 0;
    let claimed = 0;

    list.sort((a, b) => a.value - b.value).forEach((item) => {
      if (item.value <= points) {
        claimed = item.value;
      }
      if (item.value > points && next_value === 0) {
        next_value = item.value;
      }
    });
    return {claimed, next_value, progress: (points - claimed) / (next_value - claimed) * 100};
  }

  async claimReward(item: PriceItem) {
    if (item.isDisabled) {
        return;
    }
    this.isVerifying = false;
    this.isTransacting = true;
    const result = await this._clickerRewardsService.claimProduct(this._session.address!!, item.id, () => {
      this.isVerifying = true;
    });
    this.isTransacting = false;
    this.isLoading = false;
    if (result.success) {
      this._toastService.open('Reward claimed', ToastType.SUCCESS);
    }
  }

  tooltip: PriceItem | undefined;

  enterTooltip(event: any, item: PriceItem) {
    //show tooltip
    this.tooltip = item;
  }

  leaveTooltip(event: any) {
      //hide tooltip
    this.tooltip = undefined;
  }

  closeModal() {
    this.onClose.emit(true);
  }

}
