
<app-game-buttons [homeData]="homeData" *ngIf="homeData" (refreshData)="this.refresh()" (togglePhaser)="refreshEnablePhaser($event)"></app-game-buttons>
<phaser-game/>
<app-email-dialog/>
<app-ref-code-dialog *ngIf="showRefCodeModal" (onClose)="closeShowRefModal($event)"/>
<app-wrong-network *ngIf="isWrongNetwork"></app-wrong-network>
<app-toolbar [homeData]="homeData" [leaderboardData]="leaderboardData" (openPricesModal)="openPricesModal()"></app-toolbar>
<app-tutorial *ngIf="showTutorial" (onClose)="showTutorial=false; refreshEnablePhaser()" (onMoreInfo)="showTutorial=false; refreshEnablePhaser()"></app-tutorial>
<ngx-toast-center style="z-index: 1000; position:relative;"></ngx-toast-center>
<app-prices-modal *ngIf="showPricesModal" (onClose)="showPricesModal=false; refreshEnablePhaser()"></app-prices-modal>
