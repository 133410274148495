import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PhaserGame} from '../game/phaser-game.component';
import {CommonModule} from '@angular/common';
import {EventBus} from '../game/EventBus';
import {EmailDialogComponent} from "./dialog/email-dialog/email-dialog.component";
import {ModalService} from "./dialog/email-dialog/ModalService";
import {WalletButtonComponent} from "./shared/wallet-button/wallet-button.component";
import {WrongNetworkComponent} from "./shared/wrong-network/wrong-network.component";
import {TutorialComponent} from "./shared/tutorial/tutorial.component";
import {ToolbarComponent} from "./shared/toolbar/toolbar.component";
import {SessionQuery} from "./core/stores/session/session.query";
import {SessionStore} from "./core/stores/session/session.store";
import {ConnectorService} from "./core/services/connector.service";
import {RefCodeDialogComponent} from "./dialog/ref-code-dialog/ref-code-dialog.component";
import {HomeService} from "./core/services/home.service";
import {HomeData, LeaderboardData} from "./core/stores/session/models";
import {RefCodeService} from "./core/services/ref-code.service";
import {getNetworkId} from "./core/utils/web3modal";
import {NotificationService} from "./core/services/notification.service";
import {GameButtonsComponent} from "./shared/game-buttons/game-buttons.component";
import {NgxToastModule} from "@angular-magic/ngx-toast";
import {PricesModalComponent} from "./shared/prices-modal/prices-modal.component";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, PhaserGame, EmailDialogComponent, WalletButtonComponent, WrongNetworkComponent, TutorialComponent, ToolbarComponent, RefCodeDialogComponent, GameButtonsComponent, NgxToastModule, PricesModalComponent],
    templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {

    public canMoveSprite = false;

    showTutorial = false;
    showPricesModal = false;
    showRefCodeModal = false

    readonly chain = getNetworkId()
    isWrongNetwork = false;

    homeData: HomeData | undefined;
    leaderboardData: LeaderboardData | undefined;

    @ViewChild(PhaserGame) phaserRef!: PhaserGame;

    constructor(
        protected modalService: ModalService,
        private _session: SessionQuery,
        private _sessionStore: SessionStore,
        protected connectorService: ConnectorService,
        protected homeService: HomeService,
        private _refCodeService: RefCodeService,
        private _notificationService: NotificationService,
    ) {
        this._session.address$.subscribe(address => {
            if (address) {
                this.refresh()
            } else {
                this.showRefCodeModal = false;
                this.homeData = undefined;
                this.leaderboardData = undefined;
            }
        });
        connectorService.watchAccount$.subscribe(account => {
            //console.log('isConnected: ' + account.isConnected + ', chain: ' + !account.chainId + ' (' + account.chain + ')')
            this.isWrongNetwork = account.isConnected && account.chain?.id !== this.chain.id
            this.refreshEnablePhaser();
        })
        EventBus.on('scene-field-loaded', () => {
            this.showTutorial = !(this._sessionStore.getValue()?.isTutorialWatched ?? false)
            this.connectorService.sendAccountToPhaser();
            this._notificationService.requestPermission();
        })
    }

    refreshEnablePhaser(enable = true) {
        this.phaserRef.game.input.enabled = !this.isWrongNetwork && !this.showTutorial && !this.showRefCodeModal && !this.showPricesModal && enable;
    }

    closeShowRefModal(success: boolean) {
        this.showRefCodeModal = false;
        this.refreshEnablePhaser();
        if (success) {
            this.refresh();
        }
    }

    ngAfterViewInit() {
        EventBus.on('current-scene-ready', (scene: Phaser.Scene) => {
            this.canMoveSprite = scene.scene.key !== 'MainMenu';
        });
        EventBus.on('show-email-dialog', (scene: Phaser.Scene) => {
            this.modalService.open('email-dialog');
        });
        EventBus.on('show-ref-dialog', () => {
           this.openRefCodeModal();
        });
        EventBus.on('copy-address', () => {
            const refUrl = this._session.address ? 'https://brigadegame.io/?ref=' + this._session.address : '';
            navigator.clipboard.writeText(refUrl).then(
                () => {
                }, () => {
                    alert('Failed to copy');
                });
        });
        EventBus.on('open-tutorial', (scene: Phaser.Scene) => {
            this.showTutorial = true;
        });
        EventBus.on('connect-wallet', (scene: Phaser.Scene) => {
            this.connectorService.connect();
        });
        EventBus.on('sign-harvest', (scene: Phaser.Scene) => {
            this.connectorService.harvest(() => {
                EventBus.emit('processing-tx')
            }).then(r => {
                this.refresh();
                EventBus.emit('result-harvest', r.success)
            })
        });
    }

    isLoading = false
    showRefCode = true

    private openRefCodeModal() {
        this.showRefCodeModal = true;
        this.refreshEnablePhaser();
    }

    openPricesModal() {
        this.showPricesModal = true;
        this.refreshEnablePhaser();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;
        try {
            this.homeData = await this.homeService.getAllInfo()
            this.homeService.getLeaderboard(this._session.address!, this.homeData.points).then(result => {
                this.leaderboardData = result;
            })
            EventBus.emit('has-ref-code', this.homeData.hasRef);
            if (this.showRefCode && !this.homeData.hasRef && this._refCodeService.refCode) {
                this.showRefCode = false;
                this.openRefCodeModal();
            }
            EventBus.emit('home-data', this.homeData);

        } catch (e) {
            console.error(e)
        }
        this.isLoading = false;
    }

}
